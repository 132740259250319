<template>
  <div>
    <v-dialog
      v-model="addEditDialog"
      persistent
      fullscreen
      max-width="1300px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          {{ editMode ? `${$t('Update Purchase')} ${formData.invoice_no}` : $t('Add New Purchase') }}
        </v-card-title>

        <validation-observer
          ref="theForm"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            autocomplete="off"
            @submit.prevent="handleSubmit(submit)"
            @reset.prevent="reset"
          >
            <v-card-text>
              <v-row no-gutters>
                <v-col
                  class="px-1"
                  cols="12"
                  sm="3"
                  md="3"
                  lg="2"
                >
                  <app-branch-select
                    v-model="formData.branch_id"
                    :disabled="editMode"
                    @branch-selected="inventoriesList = $event.inventories"
                  />
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col
                  class="px-1"
                  cols="12"
                  sm="4"
                  md="3"
                  lg="2"
                  xl="2"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Supplier')"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="formData.supplier_id"
                      :label="`* ${$t('Supplier')}`"
                      :items="suppliersList"
                      :item-text="item => item.name"
                      :item-value="item => item.id"
                      outlined
                      dense
                      :color="$_input_color()"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="4"
                  md="3"
                  lg="auto"
                  xl="auto"
                >
                  <app-inventory-select
                    v-model="formData.inventory_id"
                    required
                    :disabled="editMode"
                    :items="inventoriesList"
                  />
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="4"
                  md="3"
                  lg="2"
                  xl="2"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Supplier Invoice No.')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="formData.supplier_invoice_no"
                      :label="$t('Supplier Invoice No.')"
                      outlined
                      dense
                      :color="$_input_color()"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="4"
                  md="3"
                  lg="2"
                  xl="2"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Date')"
                  >
                    <app-date-picker
                      v-model="formData.action_date"
                      :label="$t('Date')"
                      no-title
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="4"
                  md="6"
                  lg
                  xl
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Note')"
                  >
                    <v-text-field
                      v-model="formData.note"
                      :label="$t('Note')"
                      outlined
                      dense
                      :color="$_input_color()"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="4"
                  md="3"
                  lg="auto"
                  xl="auto"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Cash or Debt')"
                    rules="required"
                  >
                    <v-radio-group
                      v-model="formData.is_cash"
                      row
                      :error-messages="errors"
                      class="mt-1"
                    >
                      <v-radio
                        :label="$t('Cash')"
                        :value="true"
                        color="primary"
                        :ripple="false"
                      />
                      <v-radio
                        :label="$t('Debt')"
                        :value="false"
                        color="warning"
                        :ripple="false"
                      />
                    </v-radio-group>
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row
                no-gutters
                class="justify-space-between"
              >
                <v-col
                  class="px-1"
                  cols="12"
                  sm="5"
                  md="5"
                  lg="4"
                >
                  <app-item-search
                    :branch-id="formData.branch_id"
                    add-item
                    @selected="handleItemSelect($event)"
                  />
                </v-col>

                <v-col
                  class="px-1 d-flex justify-center align-center"
                  cols="12"
                  sm
                >
                  <!-- <div class="text-subtitle-1">
                    {{ $t('Total Quantity') }}:
                    {{ $_format_number(formData.total_quantity) }}
                  </div> -->

                  <div class="text-h6 font-weight-bold">
                    {{ $t('Net Price') }}:
                    {{ $_format_number(formData.net_price) }}
                  </div>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                >
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th style="width: 150px">
                            {{ $t('Code') }}
                          </th>
                          <th style="width: 200px">
                            {{ $t('Name') }}
                          </th>
                          <th
                            style="width: 130px"
                            class="text-center"
                          >
                            {{ $t('Quantity') }}
                          </th>
                          <th
                            style="width: 150px"
                            class="text-center"
                          >
                            {{ $t('Purchase Price') }}
                          </th>
                          <th
                            style="width: 130px"
                            class="text-center"
                          >
                            {{ $t('Total Purchase Price') }}
                          </th>
                          <th
                            style="width: 130px"
                            class="text-center"
                          >
                            {{ $t('Sale Price') }}
                          </th>
                          <th
                            style="width: 80px"
                            class="text-center"
                          >
                            {{ $t('Actions') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(detail, index) in formData.details"
                          :key="index"
                        >
                          <td>{{ detail.item_code }}</td>
                          <td>{{ detail.item_name }}</td>
                          <td>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="$t('Quantity') + (index + 1)"
                              rules="required|min_value:0.1"
                            >
                              <v-currency-field
                                v-model="detail.quantity"
                                :label="`* ${$t('Quantity')}`"
                                outlined
                                dense
                                single-line
                                hide-details="auto"
                                :class="$_small_input()"
                                :color="$_input_color()"
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </td>

                          <td>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="$t('Purchase Price') + (index + 1)"
                              rules="required|min_value:0.1"
                            >
                              <v-currency-field
                                v-model="detail.purchase_price"
                                :label="`* ${$t('Purchase Price')}`"
                                outlined
                                dense
                                single-line
                                hide-details="auto"
                                :class="$_small_input()"
                                :color="$_input_color()"
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </td>

                          <td class="text-center">
                            {{ $_format_number(detail.quantity * detail.purchase_price) }}
                          </td>

                          <td>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="$t('Sale Price') + (index + 1)"
                              rules="required|min_value:0.1"
                            >
                              <v-currency-field
                                v-model="detail.sale_price"
                                :label="`* ${$t('Sale Price')}`"
                                outlined
                                dense
                                single-line
                                hide-details="auto"
                                :class="$_small_input()"
                                :color="$_input_color()"
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </td>

                          <td class="text-center">
                            <v-btn
                              v-if="!detail.id"
                              color="error"
                              icon
                              small
                              class="rounded"
                              depressed
                              outlined
                              @click="removeDetail(index)"
                            >
                              <v-icon class="mdil-rotate-45">
                                mdil-plus
                              </v-icon>
                            </v-btn>

                            <v-btn
                              v-if="detail.id"
                              color="error"
                              icon
                              small
                              class="rounded"
                              depressed
                              outlined
                              @click="deleteDetail(detail, index)"
                            >
                              <v-icon small>
                                mdil-delete
                              </v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <!-- <div
                  style="overflow-x: scroll"
                  class="mt-4"
                >
                  
                </div> -->
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions class="py-3">
              <app-form-tip />

              <v-spacer />

              <v-btn
                depressed
                outlined
                small
                @click="closeDialog()"
              >
                {{ $t('Cancel') }}
              </v-btn>

              <app-submit-button :edit-mode="editMode" />
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  props: {
    addEditDialog: { type: Boolean, default: false },
    formData: { type: Object, default: () => { } },
    editMode: { type: Boolean, default: false },
  },

  data() {
    return {
      dateMenu: false,
      inventoriesList: [],
    }
  },

  computed: {
    ...mapGetters(['suppliersList'])
  },

  watch: {
    formData: {
      immediate: false,
      deep: true,
      handler() {
        this.calculate()
      }
    }
  },

  methods: {
    handleItemSelect(item) {
      const foundItem = this.formData.details.find(detail => detail.item_id == item.id)

      if (foundItem) {
        return this.$_notify('This item already is in the list.', 'warning', 4000)
      }

      this.formData.details.push({
        item_code: item.code,
        item_name: item.name,
        item_id: item.id,
        purchase_price: item.purchase_price,
        sale_price: item.sale_price,
      })
    },

    removeDetail(index) {
      this.formData.details.splice(index, 1)
    },

    deleteDetail(detail, index) {
      this.$_confirm().then(confirmed => {
        if (confirmed) {
          axios.delete(`purchase-detail/${detail.id}/`).then(() => {
            this.$_notify('Deleted successfully')
            this.formData.details.splice(index, 1)
          })
        }
      })
    },

    calculate() {
      let netPrice = 0
      let totalQuantity = 0

      let qty = 0
      let pPrice = 0
      this.formData.details.forEach(detail => {
        qty = detail.quantity ?? 0
        pPrice = detail.purchase_price ?? 0

        netPrice += qty * pPrice
        totalQuantity += qty
      })

      Vue.set(this.formData, 'net_price', netPrice)
      Vue.set(this.formData, 'total_quantity', totalQuantity)
    },

    closeDialog() {
      this.$refs.theForm.reset()
      this.$emit('close')
    },

    submit() {
      this.$_confirm({ type: 'approve' }).then(confirmed => {
        if (confirmed) {
          if (this.editMode) {
            axios.put(`purchase/${this.formData.id}/`, this.formData).then(() => {
              this.$_notify('Updated successfully')
              this.closeDialog()
              this.$emit('update-table-data')
            })
          } else {
            axios.post('purchase/', this.formData).then(() => {
              this.$_notify('Added successfully')
              this.closeDialog()
              this.$emit('reset-table-data')
            })
          }
        }
      })
    }
  },
}
</script>
<style lang="scss">
</style>