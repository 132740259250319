import { render, staticRenderFns } from "./Purchases.vue?vue&type=template&id=026c8b8d&scoped=true&"
import script from "./Purchases.vue?vue&type=script&lang=js&"
export * from "./Purchases.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "026c8b8d",
  null
  
)

export default component.exports